import React from "react";
import loadable from "@loadable/component";

const PageContainer = loadable(() => import("@layout/DefaultPageContainer"));

const PageHero = loadable(() => import("@organisms/PageHero"));
const EventsList = loadable(() => import("@organisms/EventsList"));
const EndofPageCta = loadable(() => import("@organisms/EndofPageCta"));

const HomePage = ({ data, pageContext, ...rest }) => {
  const { hero, cta, events, enableFeaturedEvent } = data;
  return (
    <PageContainer {...rest} flex={false}>
      <PageHero {...hero} patternColor="white" />
      <EventsList
        enableFeaturedEvent={enableFeaturedEvent}
        events={events}
        type={pageContext.category || "all"}
      />
      <EndofPageCta {...cta} />
    </PageContainer>
  );
};

HomePage.defaultProps = {};

export default HomePage;
