import React from "react";
import { graphql } from "gatsby";
import resolveImage from "@dataResolvers/resolveImage";
import resolveEvent from "@dataResolvers/resolveEvent";
import PageTemplate from "@pageTemplates/EventsIndexPage";
import QueryContainer, { HeadTemplate } from "../QueryContainer";

export const query = graphql`
  query EventsIndexQuery {
    craft {
      entries(section: "events") {
        title
        postDate
        uid
        url
        slug
        ... on Craft_events_default_Entry {
          heroHeading: heading0
          heroDescriptor: descriptor0
          heroBackgroundImage: image0 {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          startDateTime
          endDateTime
          formHeading: heading1
          formDescriptor: descriptor1
          formId: heading2
          # Meta
          slug
          url
          title
          metaTitle
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
      }
      entry(section: "eventsIndex") {
        title
        ... on Craft_eventsIndex_eventsIndex_Entry {
          enableFeaturedEvent: boolean0
          featuredEvent {
            uid
          }
          # Hero
          heroHeading: heading0
          heroDescriptor: descriptor0
          heroImage: image0 {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          # Meta
          slug
          url
          metaTitle
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entries, entry }) => {
  const {
    // Hero
    heroHeading,
    heroDescriptor,
    heroButtons,
    heroImage,
    // Meta
    url,
    title,
    metaTitle,
    metaDescription,
    metaImage,
    slug,
    enablefeaturedEvent,
    featuredEvent: fEvent,
  } = entry;

  let featuredEvent;
  let filteredEvents = entries.map(resolveEvent);
  // .filter(e => e.uid !== featuredEvent?.[0]?.uid);
  if (fEvent[0]) {
    featuredEvent = filteredEvents.find(f => f.uid === fEvent[0].uid);
    if (featuredEvent) {
      filteredEvents = [
        featuredEvent,
        ...filteredEvents.filter(f => f.uid !== featuredEvent.uid),
      ];
    }
  }

  return {
    hero: {
      heading: heroHeading,
      descriptor: heroDescriptor,
      buttons: heroButtons,
      image: resolveImage(heroImage),
      color: "teal",
    },
    enablefeaturedEvent,
    events: filteredEvents,
    meta: {
      url,
      title,
      metaPageTitle: metaTitle,
      metaDescription,
      metaFeaturedImage: resolveImage(metaImage),
      slug,
    },
  };
};

export const Head = ({ data, ...rest }) => {
  const { craft } = data;
  const resolvedData = dataResolver(craft);
  return <HeadTemplate {...rest} data={resolvedData} />;
};

const Page = props => (
  <QueryContainer
    template={PageTemplate}
    dataResolver={dataResolver}
    queryName="EventsIndexQuery"
    {...props}
  />
);

export default Page;
